import {Component} from '@angular/core';
import {MediaReplayService} from './core/sidenav/mediareplay/media-replay.service';
import {PreviousUrlService} from './_services/previous-url.service';
import {IndexedDBService} from './_services/indexeddb.service';
import {LocalStorageService} from './_services/local-storage.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {
	// noinspection JSUnusedLocalSymbols
	constructor(private mediaReplayService: MediaReplayService,
				private previousUrlService: PreviousUrlService,
				private localStorageService: LocalStorageService,
				private translate: TranslateService,
				private indexedDBService: IndexedDBService) {
		let userLanguage = this.localStorageService.get('user-language');
		if (userLanguage !== null) {
			this.translate.use(userLanguage);
		}
	}
}
